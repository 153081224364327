const CircleNext = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66667 20C1.66667 9.87478 9.87478 1.66667 20 1.66667C30.1252 1.66667 38.3333 9.87479 38.3333 20C38.3333 30.1252 30.1252 38.3333 20 38.3333C9.87478 38.3333 1.66667 30.1252 1.66667 20ZM1.74846e-06 20C2.7141e-06 8.95431 8.95431 4.91529e-06 20 5.88094e-06C31.0457 6.84658e-06 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 7.8281e-07 31.0457 1.74846e-06 20ZM17.5705 24.9136C17.2451 25.2687 17.2451 25.8446 17.5705 26.1997C17.8959 26.5549 18.4236 26.5549 18.749 26.1997L23.749 20.743C24.0745 20.3878 24.0745 19.8119 23.749 19.4568L18.749 14C18.4236 13.6448 17.8959 13.6448 17.5705 14C17.2451 14.3552 17.2451 14.931 17.5705 15.2862L21.9813 20.0999L17.5705 24.9136Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export default CircleNext
